import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Snackbar, TextField } from '@mui/material';
import { FormatNumberBy3, RemoveFormatNumberBy3 } from '../../utils';
import { addTransaction, removeTransaction, updateTransaction } from '../../services/User';
import InputMask from 'react-input-mask';
import {convertToPersianDate,convertToGregorianDate} from './../../utils/index'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #0006',
  boxShadow: 24,
  p: 4,
  borderRadius:5
};

const MaskedTextField = ({ mask,value,onChange, ...props }) => {
  return (
    <InputMask mask={mask} maskChar="_" alwaysShowMask value={value} onChange={onChange}>
      {() => <TextField {...props} />}
    </InputMask>
  );
};

export default function DepositPage({user,isDeposit,item,onClose}) {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false);if(onClose) onClose();}
  const[amount,setAmount]=React.useState(item?.amount);
  const[desc,setDesc]=React.useState(item?.description);
  const[trDate,setTRDate]=React.useState(convertToPersianDate(item?.transactionDate ?? new Date()));//convertToPersianDate(new Date()
  const[message,setMessage]=React.useState();
  const updateMode=item!=null && item!=undefined;
  const handleSubmit=async()=>{
    let data={
      Id:item?.id ?? 0,
      UserId:item?.userId ?? user.id,
      IsDeposit:isDeposit,
      Amount:parseInt(amount),
      Description:desc,
      TransactionDate:convertToGregorianDate(trDate)
    };
    const res=!updateMode ? await addTransaction(data) : await updateTransaction(data);
    if(res)
    {
      setMessage('با موفقیت ثبت شد');
      setTimeout(() => {
        handleClose();
      }, 1000);
    }
    else
        setMessage('عملیات ناموفق')
  }
  const handleRemove=async()=>{
    const res= await removeTransaction(item.id);
    if(res)
    {
      setMessage('با موفقیت حذف شد');
      setTimeout(() => {
        handleClose();
      }, 1000);
    }
    else
        setMessage('عملیات ناموفق')
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        style={{direction:'rtl'}}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {`${isDeposit ? 'دریافت از' : 'پرداخت به'} ${user.name+' '+user.family}`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          {/* <TextField value={FormatNumberBy3(amount)} onChange={(text)=>setAmount(RemoveFormatNumberBy3(text))} style={{width:"100%",direction:'rtl'}} id="outlined-basic" label="مبلغ" variant="outlined" /> */}
          <TextField disabled={updateMode} inputProps={{inputMode: 'numeric'}} helperText={`مبلغ ${isDeposit ? 'دریافتی':'پرداختی'} را به ریال وارد نمایید`} value={FormatNumberBy3(amount)} onChange={(text)=>setAmount(RemoveFormatNumberBy3(text.target.value))} style={{width:"100%",direction:'rtl'}} autoFocus id="outlined-basic" label="مبلغ" variant="outlined" />
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          {/* <TextField value={FormatNumberBy3(amount)} onChange={(text)=>setAmount(RemoveFormatNumberBy3(text))} style={{width:"100%",direction:'rtl'}} id="outlined-basic" label="مبلغ" variant="outlined" /> */}
          <TextField multiline  inputProps={{inputMode: 'text'}} helperText={`توضیحات تراکنش را وارد نمایید`} value={desc} onChange={(text)=>setDesc(text.target.value)} style={{width:"100%",direction:'rtl',marginTop:20,marginBottom:20}} id="outlined-basicDesc" label="توضیحات" variant="outlined" />
          <div>
              <MaskedTextField
                label="فرمت تاریخ تراکنش (YYYY/MM/DD)"
                mask="9999/99/99"
                fullWidth
                variant="outlined"
                margin="normal"
                value={trDate}
                onChange={(e)=>setTRDate(e.target.value)}
              />
          </div>
          </Typography>
          <div style={{textAlign:'end',marginTop:10}}>
          {updateMode && <Button style={{marginRight:10,justifyContent:'center'}} variant='contained' color='error' aria-required onClick={handleRemove}>حذف تراکنش</Button>}
          <Button style={{marginRight:10,justifyContent:'center'}} variant='contained' aria-required onClick={handleSubmit}>ثبت</Button>
          <Button style={{marginRight:10}} variant='outlined' aria-required onClick={handleClose}>انصراف</Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
  open={message}
  autoHideDuration={6000}
  //onClose={handleClose}
  message={message}
  //action={action}
/>
    </div>
  );
}
