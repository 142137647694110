import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Snackbar, TextField } from '@mui/material';
import { FormatNumberBy3, RemoveFormatNumberBy3 } from '../../utils';
import { addTransaction, addUser, updateUser } from '../../services/User';
import { json } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AddUserPage({user,isDeposit,onClose,onSuccessAdd}) {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false);if(onClose) onClose();}
  const[model,setModel]=React.useState(user);
  const[message,setMessage]=React.useState();
  const updateMode=user!=null && user!=undefined;
  const handleSubmit=async()=>{
      let data={
        Id:model.id,
        Name:model.name,
        Family:model.family,
        Mobile:model.mobile,
    };
    const res=!updateMode ? await addUser(data) : await updateUser(data);
    if(res)
      if(onSuccessAdd)
        onSuccessAdd();
      else  setMessage('عملیات موفق')
    else
        setMessage('عملیات ناموفق')
  }
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        style={{direction:'rtl'}}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {`${updateMode ? ' ویرایش اطلاعات '+user.name+' '+user.family:'اضافه کردن کاربر جدید'}`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          {/* <TextField value={FormatNumberBy3(amount)} onChange={(text)=>setAmount(RemoveFormatNumberBy3(text))} style={{width:"100%",direction:'rtl'}} id="outlined-basic" label="مبلغ" variant="outlined" /> */}
          <TextField disabled={updateMode} value={model?.id} onChange={(text)=>setModel({...model,id:(text.target.value)})} style={{width:"100%",direction:'rtl',marginBottom:10}} autoFocus id="outlined-basic" label="کد کاربر" type='number' inputProps={{inputMode: 'numeric'}} variant="outlined" />
          <TextField value={model?.name} onChange={(text)=>setModel({...model,name:(text.target.value)})} style={{width:"100%",direction:'rtl',marginBottom:10}} autoFocus id="outlined-basic" label="نام" variant="outlined" />
          <TextField value={model?.family} onChange={(text)=>setModel({...model,family:(text.target.value)})} style={{width:"100%",direction:'rtl',marginBottom:10}} autoFocus id="outlined-basic" label="نام خانوادگی" variant="outlined" />
          <TextField type="number" value={model?.mobile} onChange={(text)=>setModel({...model,mobile:(text.target.value)})} style={{width:"100%",direction:'rtl',marginBottom:10}} autoFocus id="outlined-basic" label="موبایل" inputProps={{inputMode: 'tel'}} variant="outlined" />
          </Typography>
          <div style={{textAlign:'end',marginTop:10}}>
          <Button style={{marginRight:10,justifyContent:'center'}} variant='contained' aria-required onClick={handleSubmit}>ثبت</Button>
          <Button style={{marginRight:10}} variant='outlined' aria-required onClick={handleClose}>انصراف</Button>
          </div>
        </Box>
      </Modal>
      <Snackbar
  open={message}
  autoHideDuration={6000}
  //onClose={handleClose}
  message={message}
  //action={action}
/>
    </div>
  );
}
