import React, { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { deepOrange } from "@mui/material/colors";
import { FormatNumberBy3, stringAvatar } from "../../utils";
import { Button, Icon, Paper } from "@mui/material";
import { AddCard, AddCardRounded, ShoppingCartCheckoutRounded, ShoppingCartRounded } from "@mui/icons-material";
import DepositPage from "../../pages/modal/DepositPage";
import TransactionListModal from "../../pages/modal/TransactionListModal";
import AddUserPage from "../../pages/modal/AddUserPage";
export default function UserCard({item}) {
    const[isDeposit,setIsDeposit]=useState(undefined);
    const[showTR,setShowTR]=useState(undefined);
    const[showEdit,setShowEdit]=useState();
  return (
    <div>
        <ListItem alignItems="flex-start">
        <ListItemAvatar onClick={()=>setShowEdit(true)}>
          {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
          {/* <Avatar sx={{ bgcolor: deepOrange[500] }} alt={item.name+" "+item.family} src="/broken-image.jpg" /> */}
          <Avatar {...stringAvatar(item.name+" "+item.family)} />
        </ListItemAvatar>
        <ListItemText onClick={()=>setShowTR(true)} style={{textAlign:'start',minWidth:'50%'}}
          primary= {item.name+" "+item.family}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color={"text.secondary"}
              >
                { item.totalAmount ? FormatNumberBy3(item.totalAmount)+" ریال - ":""}{item.id}
              </Typography>
            </React.Fragment>
          }
        />
        <ListItem style={{maxWidth:120}}>
        <AddCardRounded onClick={()=>setIsDeposit(true)} style={{marginLeft:10,cursor:'pointer'}} color="error" />
        <ShoppingCartCheckoutRounded onClick={()=>setIsDeposit(false)} style={{marginLeft:10,cursor:'pointer'}} color="warning" />
        </ListItem>
      </ListItem>
      {isDeposit!=undefined && <DepositPage user={item} isDeposit={isDeposit} onClose={()=>setIsDeposit(undefined)}/>}
      {showTR!=undefined && <TransactionListModal user={item} onClose={()=>setShowTR(undefined)}/>}
      {showEdit && <AddUserPage onClose={()=>setShowEdit(false)} user={item} />}
    </div>
  )
}
