import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import UserList from './UserList';
import { Button, CircularProgress, TextField } from '@mui/material';
import { addUser, getDashboard, getNotPaidUsers, getTransactionList, getUserList } from '../services/User';
import TransactionList from './TransactionList';
import Dashboard from './Dashboard';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function HomePage() {
  const [value, setValue] = React.useState(2);
  const[data,setData]=React.useState([]);
  const[filterData,setFilterData]=React.useState([]);
  const[dataNotPaid,setDataNotPaid]=React.useState([]);
  const[transactions,setTransactions]=React.useState([]);
  const[dashboardData,setDashboardData]=React.useState({});
  const[loading,setLoading]=React.useState(false);
  const initTransactions = async () => {
    setLoading(true);
    const list= await getTransactionList();
    setFilterData(list);
    setTransactions(list);
    setLoading(false);
  };
  const setUserList = async () => {
        setLoading(true);
        setData([]);
        const list= await getUserList();
        setFilterData(list);
        setData(list);
        setLoading(false);
    };
    const setUserListNotPaid = async () => {
      setLoading(true);
        const list= await getNotPaidUsers();
        setFilterData(list);
        setDataNotPaid(list);
        setLoading(false);
    };
    const initDashboard = async () => {
      setLoading(true);
        const res= await getDashboard();
        if(res==null) return;
        setDashboardData( {info:{remain:res.remain,totalBedehkari:res.totalBedehkari,totalBestankari:res.totalBestankari}})
        setLoading(false);
    };
  const handleChange = async(event, newValue) => {
    if(newValue==0)
        await setUserList();
    else if(newValue==1)
        await initTransactions();
    else if(newValue==2)
        await initDashboard();
    else if(newValue==3)
        await setUserListNotPaid();
    setValue(newValue);
  };
  React.useEffect(() => {
    if(value==2)
        handleChange(null,value);
  }, [value])
  
  const handleSearch=(event)=>{
    let txt=event.target.value;
    let filter=[];
    if(value==0)
       filter=data.filter(p=>p.name.includes(txt) || p.mobile.toString().includes(txt) || p.id.toString().includes(txt) || p.family.includes(txt));// || p.family?.includes(family) || p.id.includes(txt) || p.mobile.includes(txt));
    else if(value==1)
      filter=transactions.filter(p=>p.name.includes(txt) || p.mobile.toString().includes(txt) || p.userId.toString().includes(txt) || p.family.includes(txt));// || p.family?.includes(family) || p.id.includes(txt) || p.mobile.includes(txt));
    else if(value==3)
      filter=dataNotPaid.filter(p=>p.name.includes(txt) || p.mobile.toString().includes(txt) || p.id.toString().includes(txt) || p.family.includes(txt));// || p.family?.includes(family) || p.id.includes(txt) || p.mobile.includes(txt));
    setFilterData(filter);
  }
  const handleOnSuccessAddUser=()=>{
    setUserList();
  }
  return (
    <Box sx={{ width: '100%',direction:'rtl' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="لیست اعضا" {...a11yProps(0)} />
          <Tab label="آخرین تراکنش ها" {...a11yProps(1)} />
          <Tab label="داشبورد" {...a11yProps(2)} />
          <Tab label="بدهکاران" {...a11yProps(3)} />
        </Tabs>
        {loading && <CircularProgress />}
      </Box>
      <TextField style={{paddingRight:30,paddingTop:30,width:320}} onChange={handleSearch} placeholder='جستجو' variant="standard" color="warning" focused />
      <CustomTabPanel value={value} index={0}>
          <UserList data={filterData} onSuccessAdd={handleOnSuccessAddUser}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <TransactionList data={filterData} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Dashboard data={dashboardData} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <UserList data={filterData}/>
      </CustomTabPanel>
    </Box>
  );
}
