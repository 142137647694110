import React, { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { deepOrange } from "@mui/material/colors";
import { FormatNumberBy3, convertToPersianDate, stringAvatar } from "../../utils";
import { Box, Button, Icon, Paper } from "@mui/material";
import { AddCard, AddCardRounded, North, ShoppingCartCheckoutRounded, ShoppingCartRounded, South } from "@mui/icons-material";
import DepositPage from "../../pages/modal/DepositPage";
export default function TransactionCard({item,inModal=false}) {
    const[isDeposit,setIsDeposit]=useState(undefined);
    const[showEdit,setShowEdit]=useState();
  return (
    <div>
        <ListItem alignItems="flex-start">
        <ListItemAvatar>
          {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
          {/* <Avatar sx={{ bgcolor: deepOrange[500] }} alt={item.name+" "+item.family} src="/broken-image.jpg" /> */}
          <Avatar {...stringAvatar(item.name+" "+item.family)} />
        </ListItemAvatar>
        <Box
          onClick={()=>setShowEdit(true)}
          sx={{
            display: 'flex',
          }}
          style={{textAlign:'start',minWidth:"55%"}}
          flexDirection="column"
      >
        <p>{!inModal ? item.name+" "+item.family :`${FormatNumberBy3(item.amount)} ریال `}</p>
        {!inModal && <span>{`${FormatNumberBy3(item.amount)} ریال `}</span>}
        <p style={{fontSize:10}}>{item.description}</p>
      </Box>
      <Box
          sx={{
            display: 'flex',
          }}
          style={{textAlign:'end',minWidth:"20%"}}
          flexDirection="column"
      >
        <p style={{fontSize:12}}>{convertToPersianDate(item.transactionDate)} </p>
        <p style={{fontSize:10}}>{FormatNumberBy3(item.remain)} م</p>
      </Box>
        <ListItem style={{maxWidth:120}}>
        {!item.isDeposit && <North titleAccess="پرداخت شده به" style={{marginLeft:10,cursor:'pointer'}} color="error" />}
        {item.isDeposit && <South titleAccess="دریافت شده از" onClick={()=>setIsDeposit(false)} style={{marginLeft:10,cursor:'pointer'}} color="success" />}
        </ListItem>
      </ListItem>
      {showEdit && <DepositPage user={item} item={item} isDeposit={item.isDeposit} onClose={()=>setShowEdit(false)}/>}
    </div>
  )
}
