export const profileData = data => dispatch => {
    dispatch({
      type: "PROFILE_DATA",
      data
    });
  };
  
  export const showLoader = () => dispatch => {
    dispatch({
      type: "SHOW_LOADER"
    })
  }
  
  export const hideLoader = () => dispatch => {
    dispatch({
      type: "HIDE_LOADER"
    })
  }

  export const showAlert = (message,msgType) => dispatch => {
    dispatch({
      type: "SHOW_ALERT",
      message,
      msgType
    })
  }

  export const isLoggedIn = () => dispatch => {
    dispatch({
      type: "IS_LOGGEDIN",
    })
  }
  export const setBadgeCount = (data) => dispatch => {
    dispatch({
      type: "SET_BADGE_COUNT",
      data
    })
  }
  export const incBadgeCount = () => dispatch => {
    dispatch({
      type: "INC_BADGE_COUNT"
    })
  }