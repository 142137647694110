import React from 'react'
import FundCard from '../component/card/FundCard'
import { Button } from '@mui/material'
import { downloadFile } from '../utils'
import { GetBackupTransactions, GetBackupUsers } from '../services/User'
import { Backup, ExitToApp } from '@mui/icons-material'
import { logout } from '../services/User/authService'
import { useNavigate } from 'react-router-dom'

export default function Dashboard({data}) {
  const navigate = useNavigate();
  const getBackup=async()=>{
    const res=await GetBackupUsers();
    const res2=await GetBackupTransactions();
  }
  const exit=()=>{
    logout();
    navigate('/login')
  }
  return (
    <div style={{textAlign:'center'}}>
        <FundCard info={data.info} isDashboard/>
        <Button style={{marginTop:10}} startIcon={<Backup style={{marginLeft:10,marginRight:0}} />} variant='contained' color='secondary' onClick={getBackup}>گرفتن بک آپ</Button>
        <Button style={{marginTop:10}} startIcon={<ExitToApp style={{marginLeft:10,marginRight:0}} />} variant='contained' color='warning' onClick={exit}>خروج</Button>
    </div>
  )
}
