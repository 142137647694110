import React, { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { getTransactionList } from "../services/User";
import { deepOrange } from "@mui/material/colors";
import { FormatNumberBy3, stringAvatar } from "../utils";
import { Button, Paper } from "@mui/material";
import UserCard from "../component/card/UserCard";
import AddUserPage from "./modal/AddUserPage";
import TransactionCard from "../component/card/TransactionCard";


export default function TransactionList({data,isModal}) {
    const[showAddUser,setShowAddUser]=useState();
  return (
    <Paper style={{ maxHeight:!isModal ? window.innerHeight-100 : window.innerHeight-400, overflow: 'auto' }}>
    <List sx={{ width: '100%', maxWidth: 400, bgcolor: 'background.paper' }}>
      {data?.map((item,index)=>{
        return (
            <div key={item.id}>
            <TransactionCard item={item} inModal={isModal}/>
            <Divider variant="inset" component="li" style={{minWidth:'100%'}} />
      </div>
        )
      })}
    </List>
    </Paper>
  );
}
