import React from 'react'
import Navigation from '../component/Navigation';

export function Home() {
    return (<h1>
        <Navigation><div>helllow</div></Navigation>
    </h1>)
  }
  
  export function About() {
    return <h1>About Us</h1>;
  }
  
  export function Contact() {
    return <h1>Contact Us</h1>;
  }
  