import React from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Chip, Snackbar, TextField } from '@mui/material';
import { FormatNumberBy3, RemoveFormatNumberBy3 } from '../../utils';
import { addTransaction, getTransactionListUser } from '../../services/User';


export default function FundCard({info,isDashboard}) {
  return (
    <div
      style={{
        marginTop: 20,
        textAlignLast: "auto",
        border: "solid",
        textAlign:'center',
        alignContent:'center',
        boxShadow: 20,
        borderColor: "ActiveCaption",
        borderRadius: 10,
      }}
    >
      <div style={{ textAlign: "end", marginTop: 10,marginLeft:10 }}>
        {!isDashboard ? " واریزی به صندوق : ":"کل واریزی به صندوق : "}
        <Chip
          color="warning"
          style={{minWidth:100}}
          label={FormatNumberBy3(info?.totalBedehkari) + " ریال "}
        />
      </div>
      <div style={{ textAlign: "end", marginTop: 10,marginLeft:10 }}>
      {!isDashboard ? " دریافتی از صندوق : ":"میزان مبلغ وصول نشده : "}
        <Chip
          color="error"
          style={{minWidth:100}}
          label={FormatNumberBy3(info?.totalBestankari) + " ریال "}
        />
      </div>
      <div style={{ textAlign: "end", marginTop: 10,marginBottom:10,marginLeft:10 }}>
        {!isDashboard ? " بدهی به صندوق : ":"موجودی صندوق : "}
        <Chip color="success" style={{minWidth:100}} label={FormatNumberBy3(info?.remain) + " ریال "} />
      </div>
      {isDashboard && <div style={{ textAlign: "end", marginTop: 10,marginBottom:10,marginLeft:10 }}>
        {" سرمایه صندوق : "}
        <Chip color="info"  style={{minWidth:100}} label={FormatNumberBy3(info?.remain+(Math.abs(info?.totalBestankari))) + " ریال "} />
      </div>}
    </div>
  );
}
