import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Chip, CircularProgress, Snackbar, TextField } from '@mui/material';
import { FormatNumberBy3, RemoveFormatNumberBy3 } from '../../utils';
import { addTransaction, getTransactionListUser } from '../../services/User';
import TransactionList from '../TransactionList';
import FundCard from '../../component/card/FundCard';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function TransactionListModal({user,onClose}) {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {setOpen(false);if(onClose) onClose();}
  const[amount,setAmount]=React.useState();
  const[message,setMessage]=React.useState();
  const[data,setData]=React.useState();
  const[info,setInfo]=React.useState();
  const[loading,setLoading]=React.useState(false);
  React.useEffect(() => {
      init();
  }, [])
  const init=async()=>{
    setLoading(true)
    const res=await getTransactionListUser(user.id);
    console.log("UserList:",res)
    if(res)
    {
      setData(res.list);
      setInfo({remain:res.remain,totalBedehkari:res.totalBedehkari,totalBestankari:res.totalBestankari})
    }
    setLoading(false)
  }
  return (
    <div>
      <Modal
        open={open}
        style={{direction:'rtl'}}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          تراکنش های {user.name+' '+user.family+` (${user.id}) `}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 1 }} >
            {loading && <CircularProgress />}
            <TransactionList data={data} isModal/>
          </Typography>
          <FundCard info={info}/>
        </Box>
      </Modal>
    </div>
  );
}
