import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { About, Contact, Home } from "./pages/Collection";
import LogIn from "./pages/Login";
import UserList from "./pages/UserList";
import HomePage from "./pages/HomePage";

function App() {
  return (
    <Router>
      {/* <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/login">Login</Link>
          </li>
          <li>
            <Link to="/userList">کاربران</Link>
          </li>
        </ul>
      </nav> */}
    <Routes>
      <Route path="/home" exact element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/login" element={<LogIn />} />
      <Route path="/" element={<LogIn />} />
      {/* <Route path="/userList" element={<UserList />} /> */}
      </Routes>
    </Router>
  );
}


export default App;
