import axios from 'axios';
import { API_URL } from './config';
import { authHeader, authHeaderV2 } from '../services/User/auth-header';
import { store } from '../redux/Store';
import { hideLoader, showAlert, showLoader } from '../redux/Actions';
import jalaliMoment from 'jalali-moment';
import Persian from 'persianjs';

export const requestServer = async (
    address,
    data = null,
    method = "GET",
    isFile = false
  ) => {
    const requestOptions = {
      method: method,
      headers: authHeaderV2(),
      body:method==='POST' ? JSON.stringify(data) : null
    };
    store.dispatch(showLoader());
    try {
      return await fetch(API_URL + address, requestOptions)
        .then((response) => {
          store.dispatch(hideLoader());
          console.log(response.status);
          if (response.status == 403) {
            store.dispatch(
              showAlert(
                "شما مجوز دسترسی به این صفحه یا امکان انجام این عملیات را ندارید"
              )
            );
            //history.goBack();
            return false;
          } else if (response.status == 401) {
            store.dispatch(showAlert("ابتدا وارد سیستم شوید", "GO_LOGIN"));
            return;
          } else if (response.status == 404) {
            store.dispatch(showAlert("اتصال شبکه برقرار نیست"));
            //history.push("/");
          } else if (response.status == 400 || response.status == 500) {
            console.log(response);
            response.json().then((err) => {
              console.log("Error In Then", err);
              store.dispatch(showAlert(err?.message));
              //store.dispatch(showAlert("خطا " + err.title + "\r\n" + JSON.stringify(err)));
              //alert(err.title ?? err.message);
            });
            return;
          }
          if (isFile) return response;
          return response.json();
        })
        .then((data) => {
          store.dispatch(hideLoader());
          if (isFile) {
            var fileName = data.headers
              .get("content-disposition")
              .split("filename=")[1]
              .split(";")[0];
            data.blob().then((blob) => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement("a");
              a.href = url;
              a.download = fileName;
              a.click();
  
              //window.location.href = url;
              return url;
            });
          }
          return data;
        })
        .catch((error) => {
          store.dispatch(hideLoader());
          console.log(error);
          throw error;
        });
    } catch (e) {
      console.log(e);
      store.dispatch(showAlert("خطای شبکه: " + e));
      return;
    }
  };
  export const postRequest = async (endpoint, data) => {
    const url = `${API_URL}${endpoint}`;
    console.log({
      method: 'POST',
      headers: authHeaderV2(),
      body: JSON.stringify(data),
      url:url
    });
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: authHeaderV2(),
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        // Handle non-successful responses here
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      return responseData;
    } catch (error) {
      // Handle network or other errors here
      console.error('Error during API call:', error.message);
      throw error;
    }
  };
  export const Loading = () => {
    return (
      <div className="form-group text-center mb-4">
        <label htmlFor="errors" style={{ color: "red" }}>
          لطفا منتظر بمانید . . .
        </label>
        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </div>
    );
  };
function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
export function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  export const FormatNumberBy3 = (num, sep = ",") => {
    // if(num==undefined || num=="") return;
    //return num;
    var number = typeof num === "number" ? num.toString() : num,
      separator = typeof sep === "undefined" ? "," : sep;
    var nNumber = number?.replace(new RegExp(sep, "g"), "");
    return nNumber?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1" + separator);
  };
  export const RemoveFormatNumberBy3 = (num, sep = ",") => {
    try {
      return num.replace(new RegExp(sep, "g"), "");
    } catch {
      return num;
    }
  };

  export const convertToPersianDate = (gregorianDate) => {
    const persianDate = jalaliMoment(gregorianDate).locale('fa').format('YYYY/MM/DD');
    return persianDate;
  };

  export const convertToGregorianDate = (persianDate) => {
    const gregorianDate =jalaliMoment.from(persianDate, 'fa', 'YYYY/MM/DD').format('YYYY-MM-DD');
    return gregorianDate;
  };
  
  export function numberToWords(number) {
    try{
      return Persian(number).digitsToWords().toString();
  }
  catch{
    return "";
  }
  }
  export function numberToToman(number,unit=" تومان",divide=10) {
    return numberToWords(Math.round(RemoveFormatNumberBy3(number)/divide))+unit
  }


  export async function downloadFile(url) {
    const response = await fetch(API_URL+url, {
      headers: authHeader()
    });
    
    if (response.ok) {
      const blob = await response.blob();
      
      // Create a temporary link to download the file
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      // const fileName = response.headers.get('Content-Disposition')?.split("filename=")[1]?.split(";")[0];
      // console.log(response.headers.get('Content-Disposition'))
      downloadLink.download = "text_file"+new Date().toLocaleTimeString()+".txt";
      downloadLink.click();
      
      // Clean up the temporary link
      URL.revokeObjectURL(downloadLink.href);
    } else {
      // Handle error response
      console.error("Failed to download file");
    }
  }
  
