import React from 'react'
import { downloadFile, postRequest, requestServer, sendRequest } from '../../utils'
export const getUserList=async()=>{
    const res=await requestServer("user/GetUserList");
    return res;
}

export const getTransactionList=async()=>{
    return await requestServer("user/GetTransactionList");
}

export const getTransactionListUser=async(userId)=>{
    return await requestServer("user/GetTransactionListUser/"+userId);
}

export const getNotPaidUsers=async()=>{
    return await requestServer("user/GetNotPaidUsers");
}

export const addUser=async(model)=>{
    return await requestServer(`user/AddUser/${model.Id}/${model.Name}/${model.Family}/${model.Mobile}`,null,"POST");
}

export const updateUser=async(model)=>{
    return await requestServer(`user/UpdateUser/${model.Id}/${model.Name}/${model.Family}/${model.Mobile}`,null,"POST");
}

export const addTransaction=async(model)=>{
    console.log(model);
    return await requestServer(`user/AddTransaction`,model,"POST");
}

export const updateTransaction=async(model)=>{
    console.log(model);
    return await requestServer(`user/UpdateTransaction`,model,"POST");
}

export const removeTransaction=async(id)=>{
    console.log(id);
    return await requestServer(`user/RemoveTransaction/${id}`,null,"POST");
}

export const getDashboard=async()=>{
    return await requestServer("user/GetDashboard");
}

export const GetBackupTransactions=async()=>{
    return await downloadFile("user/GetBackup");
}

export const GetBackupUsers=async()=>{
    return await downloadFile("user/GetBackupUsers");
}
